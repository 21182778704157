import '../../App.css';
import React from "react";
import { Text, Box, Button, Modal, ModalOverlay, Icon, ModalContent, ModalFooter, ModalBody, useToast, IconButton, Input, InputGroup, InputRightElement} from '@chakra-ui/react';
import { SmallCloseIcon, PlusSquareIcon} from '@chakra-ui/icons'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { post, get } from '../../services/api'


const ModalAddIndustry = ({ isOpen, onClose, refreshIndustry }) => {
    const toast = useToast();
    const [industryType, setIndustryType] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [selectedSection, setSelectedSection] = React.useState([]);
    const [newSectionInput, setNewSectionInput] = React.useState(""); // New input state
    const [showNewSectionInput, setShowNewSectionInput] = React.useState(false); // Toggle input display
    const [sections, setSections] = React.useState([]);
    
    const addSections = (item) => {
      const newSelectedSections = selectedSection.includes(item)
        ? selectedSection.filter((i) => i !== item)
        : [...selectedSection, item];
  
      setSelectedSection(newSelectedSections);
  
      if (newSelectedSections.length > 0) {
        setErrors((prevErrors) => {
          const { sections, ...rest } = prevErrors;
          return rest;
        });
      }
    };

    const fetchSections = async () => {
      try {
        const response = await get('/reportmanagement/section');
        const section = response.data.data.map(item => (item.section));
        setSections(section);
      } catch (error) {
        console.error('Error fetching section:', error);
      }
    };
      
    //   fetchSections();
    // }, []);
  
    React.useEffect(() => {
      if (isOpen) {
        fetchSections();
        setErrors({});
        setIndustryType("");
        setSelectedSection([]);
      }
    }, [isOpen]);
  
    const validate = (field, value) => {
      let error = '';
  
      switch (field) {
        case 'industry':
          const industryRegex = /^[a-zA-Z0-9]+( +[a-zA-Z0-9]+)*$/;
          if (!value) {
            error = 'Industry name is required';
          }  else if (!industryRegex.test(value)) {
            error = 'Company name is invalid';
          }
          break;
        case 'sections':
          if (selectedSection.length === 0) {
            error = 'At least one section must be selected';
          }
          break;
        case 'section':
          const sectionRegex = /^[a-zA-Z0-9]+( +[a-zA-Z0-9]+)*$/;
          if (!value) {
            error = 'Section is required';
          }  else if (!sectionRegex.test(value)) {
            error = 'Section is invalid';
          }
          break;
        default:
          break;
      }
  
      return error;
    };
  
    const handleInputChange = (field, value) => {
      if (field === 'industry') {
        setIndustryType(value);
      } else if (field === 'section') {
        setNewSectionInput(value);
      }
  
      const error = validate(field, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error
      }));
    };
  
    const handleSubmit = async () => {
      const fields = [
        { field: 'industry', value: industryType },
        // { field: 'numberOfEmployees', value: numberofemployee },
      ];
  
      const validationErrors = {};
  
      fields.forEach(({ field, value }) => {
        const error = validate(field, value);
        if (error) {
          validationErrors[field] = error;
        }
      });
  
      // Validate sections
      const sectionError = validate('sections');
      if (sectionError) {
        validationErrors.sections = sectionError;
      }
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const data = {
          industryType: industryType,
          // numberofEmployee: numberofemployee,
          section: selectedSection.map(section => ({
            name: section,
            assertions: []
          }))
        };
        console.log(data);
        try {
          const response = await post(`reportmanagement/industry`, data);
          toast({
            title: response.data.message,
            description: 'New Industry has been created successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });

          setIndustryType('');
          // setNumberofemployee('');
          setSelectedSection([]);
          onClose()
          refreshIndustry()
        } catch (error) {
          console.error('Error submitting form:', error);
          if(error.response.status === 400)
          {
              // Show error toast
              toast({
                  title: 'Error',
                  description: error.response.data.message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
              });
          }
          else if(error.response.status === 404){
              toast({
                  title: 'Error',
                  description: error.response.data.message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
              });
          } else {
              toast({
                  title: 'Error',
                  description: error.response.data.message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
              });
          }
        }
      }
    };

    const isFormValid = () => {
      return !Object.values(errors).some(error => error) &&
      industryType && selectedSection.length !== 0 ;
    };

    const handleNewSection = () => {

      const sectionError = validate('section',newSectionInput);
      if (!sectionError) {
        // validationErrors.sections = sectionError;
      // } else {
        setSections([...sections, newSectionInput]);
        setNewSectionInput("");
        setShowNewSectionInput(false);
      }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent my={4} p={4} maxW={{ base: '90%', sm: '450px' }}>
          <ModalBody>
            <Box mb="5">
              <Text fontWeight="medium" fontSize='14px' mb="2">
                Name of Industry
              </Text>
              <Input
                variant='outline'
                border='1px solid #F3F4F6'
                focusBorderColor={errors.industry ? 'red.500' : '#2970FF'}
                bg="#F5F8FF"
                placeholder='Name of a Industry'
                size='md' 
                value={industryType}
                onChange={(e) => handleInputChange('industry', e.target.value)}
                borderColor={errors.industry ? 'red.500' : '#D1E0FF'}
                sx={{
                  '::placeholder': {
                      color: '#9DA4AE',
                  },
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                }}
              />
              {errors.industry && <Text color="red.500" fontSize="sm">{errors.industry}</Text>}
            </Box>
              <Box mb="5">
              <Text fontWeight="medium" fontSize='14px' mb="2">
                Choose Sections
              </Text>
              <Box display="flex" flexWrap="wrap" gap={2}>
                {sections.map((item) => {
                  const isSelected = selectedSection.includes(item);
                  return (
                    <Box
                      key={item}
                      display="inline-flex"
                      alignItems="center"
                      py={2}
                      pl={4} pr={isSelected ? 3 : 4}
                      border="1px solid"
                      borderColor={isSelected ? '#2970FF' : '#D1E0FF'}
                      backgroundColor={isSelected ? 'white' : '#F5F8FF'}
                      borderRadius="10px"
                      w="fit-content"
                      cursor="pointer"
                      onClick={() => addSections(item)}
                    >
                      <Text
                        fontWeight="450"
                        fontSize="14px"
                        color={isSelected ? '#2970FF' : 'inherit'}
                      >
                        {item}
                      </Text>
                      {isSelected && (
                        <SmallCloseIcon
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            addSections(item);
                          }}
                          ml={2}
                          color="#2970FF"
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
              <Box mt={4}>
                {!showNewSectionInput && (
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <Box display='flex' alignItems='center' width='fit-content' height='fit-content' cursor='pointer' onClick={() => setShowNewSectionInput(true)}>
                      <Icon as={PlusSquareIcon} boxSize={4} color='#2970FF' cursor='pointer' mr={1} />
                      <Text fontWeight="medium" fontSize='14px' color='#2970FF' >Add New Section</Text>
                    </Box>
                  </Box>
                )}

                {showNewSectionInput && (
                  <Box mt={2} display="flex" alignItems="center" gap={2}>
                    <InputGroup size='md'>
                      <Input
                        type='text'
                        placeholder='Add a new section'
                        value={newSectionInput}
                        variant='outline'
                        borderColor={errors.section ? '#F04438' : '#F3F4F6'}
                        focusBorderColor={errors.section ? '#F04438' : '#2970FF'}
                        bg="#FFFFFF"
                        onChange={(e) => handleInputChange('section', e.target.value)}
                        sx={{
                          '::placeholder': {
                            color: '#9DA4AE',
                          },
                        }}
                      />
                      <InputRightElement width='2.5rem'>
                        <IconButton  isDisabled={errors.addlink} size='sm' p={0} variant='ghost' _hover={{ bg: 'white' }} onClick={() => handleNewSection()}>
                          <AddCircleOutlineOutlinedIcon sx={{ fontSize: 25, color: '#9da4ae', '&:hover': { color: errors.addlink ? '#F04438' : '#2970FF', }, }} />
                        </IconButton>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                )}
                {errors.section && <Text color="red.500" fontSize="sm">{errors.section}</Text>}
              </Box>
              {errors.sections && <Text color="red.500" fontSize="sm">{errors.sections}</Text>}
            </Box>
          </ModalBody>
          <br />
          <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={() => { onClose(); setShowNewSectionInput(false)}}>Cancel</Button>
            <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalAddIndustry;

