import React from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes } from "react-router-dom";
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
    }),
    // Sentry.replayIntegration()
  ],

  tracesSampleRate: 0.5,

  // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 0.5,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);