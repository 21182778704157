import '../../App.css';
import React from "react";
import { Text, Box, Button, Textarea, Modal, Switch, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast } from '@chakra-ui/react';
import { patch } from '../../services/api';

const ModalAddAssetion = ({ isOpen, onClose, sectionId, industryTypeId, refreshSections }) => {
    const toast = useToast();
    const [assertionName, setAssertionName] = React.useState("");
    const [expectedAnswer, setExpectedAnswer] = React.useState("");
    const [require, setRequire] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        if (isOpen) {
            setErrors({});
            setAssertionName("");
            setExpectedAnswer("");
            setRequire(false);
        }
    }, [isOpen]);

    const validate = (field, value) => {
        let error = '';
        switch (field) {
            case 'assertionName':
                if (!value) {
                    error = 'Assertion name is required';
                }
                break;
            case 'expectedAnswer':
                if (!value) {
                    error = 'Expected answer is required';
                }
                break;
            default:
                break;
        }
        return error;
    };

    const handleAssertionNameChange = (value) => {
        setAssertionName(value);
        const error = validate('assertionName', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            assertionName: error
        }));
    };

    const handleExpectedAnswerChange = (value) => {
        setExpectedAnswer(value);
        const error = validate('expectedAnswer', value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            expectedAnswer: error
        }));
    };

    const handleRequireChange = () => {
        setRequire(prevRequire => !prevRequire);
    };

    const handleSubmit = async () => {
        const validationErrors = {};
        validationErrors.assertionName = validate('assertionName', assertionName);
        validationErrors.expectedAnswer = validate('expectedAnswer', expectedAnswer);

        if (Object.values(validationErrors).some(error => error)) {
            setErrors(validationErrors);
        } else {
            const data = {
                industryTypeId,
                sectionId,
                assertions: [
                    {
                        assertionQuestion: assertionName,
                        assertionAnswers: [expectedAnswer],
                        require, // Include the boolean require field
                    },
                ],
            };
            console.log(data);
            try {
                const response = await patch(`reportmanagement/industry/assertion`, data);

                toast({
                    title: response.data.message,
                    description: 'New assertion has been created successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });

                setAssertionName('');
                setExpectedAnswer('');
                setRequire(false); // Reset the require field
                refreshSections();
                onClose();
            } catch (error) {
                console.error('Error submitting form:', error);
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || 'An error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        }
    };

    const isFormValid = () => {
        const isAssertionNameValid = assertionName.trim() !== '';
        const isExpectedAnswerValid = expectedAnswer.trim() !== '';
        const noValidationErrors = !Object.values(errors).some(error => error);

        return isAssertionNameValid && isExpectedAnswerValid && noValidationErrors;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4} maxW={{ base: '90%', sm: '450px' }} >
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Assertion Name
                        </Text>
                        <Textarea
                            px={3}
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.assertionName ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            borderRadius='10px'
                            placeholder='Name of an Assertion'
                            size='md'
                            resize={'none'}
                            fontSize="14px"
                            value={assertionName}
                            onChange={(e) => handleAssertionNameChange(e.target.value)}
                            borderColor={errors.assertionName ? 'red.500' : '#D1E0FF'}
                        />
                        {errors.assertionName && <Text color="red.500" fontSize="sm">{errors.assertionName}</Text>}
                    </Box>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Expected Answer
                        </Text>
                        <Textarea
                            px={3}
                            border='1px solid #F3F4F6'
                            bg='#F5F8FF'
                            borderRadius='10px'
                            focusBorderColor={errors.expectedAnswer ? 'red.500' : '#2970FF'}
                            placeholder='Expected Answer'
                            size='md'
                            resize={'none'}
                            fontSize="14px"
                            value={expectedAnswer}
                            onChange={(e) => handleExpectedAnswerChange(e.target.value)}
                            borderColor={errors.expectedAnswer ? 'red.500' : '#D1E0FF'}
                        />
                        {errors.expectedAnswer && <Text color="red.500" fontSize="sm">{errors.expectedAnswer}</Text>}
                    </Box>
                    <Box  display="flex" alignItems="center">
                        <Text fontWeight="medium" fontSize='14px'>Required:</Text>
                        <Switch size='sm' colorScheme='blue' isChecked={require} onChange={handleRequireChange} ml={2} />
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
                        Add Assertion
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalAddAssetion;