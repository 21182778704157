import '../../App.css';
import React from "react";
import { Text, Box, Button, Modal,Input, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast, Icon } from '@chakra-ui/react';
import { patch, get } from '../../services/api';

const ModalEditSection = ({ isOpen, onClose, industryTypeId, sectionId, section, refreshSections }) => {
    const toast = useToast();
    const [sectionname, setSectionname] = React.useState(section);

    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        if (isOpen) {
            setErrors({});
            setSectionname(section);
        }
    }, [isOpen, industryTypeId, sectionId]);


    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'sectionName':
                if (!value) {
                    error = 'Section name is required';
                }
                break;
            default:
                break;
        }

        return error;
    };

    const handleSectionNameChange = (value) => {
        const error = validate('sectionName', value);
        setSectionname(value);
        setErrors(prevErrors => ({
            ...prevErrors,
            sectionName: error
        }));
    };

    const handleSubmit = async () => {
        const validationErrors = {};

        const sectionNameError = validate('sectionName', sectionname);
        if (sectionNameError) {
            validationErrors.sectionName = sectionNameError;
        }

        if (Object.values(validationErrors).some(error => error)) {
            setErrors(validationErrors);
        } else {
            const data = {
                industryTypeId: industryTypeId,
                name: sectionname
            };
            console.log(data);
            try {
                const response = await patch(`reportmanagement/industry/section/${sectionId}`, data);

                toast({
                    title: response.data.message,
                    description: 'Section name has been updated successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
                setSectionname("")
                refreshSections();
                onClose();
            } catch (error) {
                console.error('Error submitting form:', error);
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || 'An error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        }
    };

    const isFormValid = () => {
        const isSectionNameValid = sectionname.trim() !== '';
        const hasSectionNameChanged = sectionname !== section;

        return isSectionNameValid && hasSectionNameChanged ;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4} maxW={{ base: '90%', sm: '450px' }}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            Name of Section
                        </Text>
                        <Input
                            variant='outline'
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.sectionName ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            placeholder='Name of a Section'
                            fontSize="14px"
                            size='md'
                            value={sectionname}
                            onChange={(e) => handleSectionNameChange(e.target.value)}
                            borderColor={errors.sectionName ? 'red.500' : '#D1E0FF'}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                                whiteSpace: 'nowrap',
                                overflow: 'auto',
                            }}
                        />
                        {errors.sectionName && <Text color="red.500" fontSize="sm">{errors.sectionName}</Text>}
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} isDisabled={!isFormValid()} onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalEditSection;
