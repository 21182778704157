import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Grid, GridItem, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, Text, Button, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../components/form/inputField';
import PasswordField from '../../components/form/passwordField';
import SelectField from '../../components/form/selectField';
import useFormValidation from '../../hooks/useFormValidation';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import ChangePassword from '../../components/account/changePassword';
import useIndustryTypes from '../../hooks/useIndustryType';
import { get, patch } from '../../services/api';

const Edituser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  // const [industryOptions, setIndustryOptions] = useState([]);
  const industryOptions = useIndustryTypes();
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const roleOptions = ['Admin', 'User'];

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    isFormValid,
    isFormChanged,
    setInitialFormValues,
  } = useFormValidation({
    name: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    numberOfEmployees: '',
    industryType: '',
    role: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await get(`users/${userId}`);
        const userData = response.data.data;

        const initialUserData = {
          name: userData.userName,
          phoneNumber: userData.phoneNumber,
          email: userData.emailId,
          companyName: userData.companyName,
          numberOfEmployees: userData.companySize,
          industryType: userData.industryType,
          role: userData.role,
          password: '',
          confirmPassword: '',
        };

        setInitialFormValues(initialUserData);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [userId]);

  const handleSubmit = async () => {
    if (!isFormChanged()) return;

    const data = {
      userName: values.name,
      phoneNumber: values.phoneNumber,
      emailId: values.email,
      companyName: values.companyName,
      companySize: values.numberOfEmployees,
      industryType: values.industryType,
      role: values.role,
    };

    try {
      const response = await patch(`users/${userId}`, data);

      toast({
        title: 'User updated',
        description: 'User has been updated successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
        onCloseComplete: () => {
          navigate('/user');
        },
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        const errorMsg = error.response.data.message;
        if (errorMsg === 'Email is already registered') {
          handleChange('email', values.email);
          errors.email = 'Email is already registered';
        } else if (errorMsg === 'Phone number is already registered') {
          handleChange('phoneNumber', values.phoneNumber);
          errors.phoneNumber = 'Phone number is already registered';
        }
      }
    }
  };

  const handlePasswordChange = async () => {
    const passwordValid =
      !errors.password &&
      !errors.confirmPassword &&
      values.password &&
      values.confirmPassword &&
      values.password === values.confirmPassword;

    if (!passwordValid) return;

    const data = {
      password: values.password,
      confirmpassword: values.confirmPassword,
    };

    try {
      const response = await patch(`users/${userId}/password`, data);
      handleChange('password', '');
      handleChange('confirmPassword', '');

      toast({
        title: 'Password updated',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.response && error.response.data.message === 'Password does not match') {
        errors.confirmPassword = 'Passwords do not match';
      }
    }
  };

  return (
    <ChakraProvider>
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        flex="1"
        overflowY="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        w="100%"
        h="fit-content"
      >
        <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
          <Breadcrumb
            separator=">"
            color="#818589"
            fontWeight="400"
            fontSize="sm"
            mb={2}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/user">User</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/editUser/${userId}`}>Edit User</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Box>
            <Heading
              size="md"
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight="700"
              mb={2}
            >
              Edit User
            </Heading>
          </Box>
          <br />
          <Text
            fontWeight="600"
            fontSize={{ base: '18px', md: '20px' }}
            mr="8px"
          >
            User Details
          </Text>
          <Box
            w="100%"
            bg="#F5F8FF"
            p={{ base: '20px', md: '30px' }}
            h="fit-content"
            border="1px solid #D1E0FF"
            borderRadius="12px"
          >
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap={{ base: 1, md: 2, lg: 5 }}
            >
              <GridItem w="100%" h="fit-content">
                <InputField
                  label="Name of the User"
                  value={values.name}
                  error={errors.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                  onBlur={() => handleBlur('name')}
                  placeholder="Name of the user"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                  label="Phone Number (e.g.: 98XXXXXXXX)"
                  type="number"
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                  onChange={(e) => handleChange('phoneNumber', e.target.value)}
                  onBlur={() => handleBlur('phoneNumber')}
                  placeholder="Phone Number"
                  onKeyDown={(e) => {
                    if (e.key === '.' || e.key === 'Decimal') {
                      e.preventDefault();
                    }
                  }}
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                  label="Email Address"
                  value={values.email}
                  error={errors.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  onBlur={() => handleBlur('email')}
                  placeholder="Enter your email address"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                  label="Company Name"
                  value={values.companyName}
                  error={errors.companyName}
                  onChange={(e) => handleChange('companyName', e.target.value)}
                  onBlur={() => handleBlur('companyName')}
                  placeholder="Enter Company Name"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <InputField
                  label="Company Size"
                  type="number"
                  value={values.numberOfEmployees}
                  error={errors.numberOfEmployees}
                  onChange={(e) => handleChange('numberOfEmployees', e.target.value)}
                  onBlur={() => handleBlur('numberOfEmployees')}
                  placeholder="Enter the size of the company in no. of employees"
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <SelectField
                  label="Select the Industry type"
                  options={industryOptions}
                  selectedOption={values.industryType}
                  setSelectedOption={(option) => handleChange('industryType', option)}
                  error={errors.industryType}
                  isRequired
                />
              </GridItem>
              <GridItem w="100%" h="fit-content">
                <SelectField
                  label="Select the Role"
                  options={roleOptions}
                  selectedOption={values.role}
                  setSelectedOption={(option) => handleChange('role', option)}
                  error={errors.role}
                  isRequired
                />
              </GridItem>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="fit-content"
              cursor="pointer"
              onClick={() => setShowPasswordFields(!showPasswordFields)}
              mt={4}
            >
              <Text color="#2970FF" fontSize="14px" fontWeight="700" mr={1}>
                Change Password
              </Text>
              {showPasswordFields ? (
                <ChevronUpIcon color="#2970FF" />
              ) : (
                <ChevronDownIcon color="#2970FF" />
              )}
            </Box>
          </Box>
          {showPasswordFields && (
            <>
              <br />
              {/* <br />
              <Text
                fontWeight="600"
                fontSize={{ base: '18px', md: '20px' }}
                mr="8px"
              >
                Change Password
              </Text>
              <Box
                w="100%"
                bg="#F5F8FF"
                p={{ base: '20px', md: '30px' }}
                h="fit-content"
                border="1px solid #D1E0FF"
                borderRadius="12px"
              >
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                  gap={{ base: 1, md: 2, lg: 5 }}
                >
                  <GridItem w="100%" h="fit-content">
                    <PasswordField
                      label="Password"
                      value={values.password}
                      error={errors.password}
                      onChange={(e) => handleChange('password', e.target.value)}
                      onBlur={() => handleBlur('password')}
                      placeholder="Enter your password"
                      isRequired
                    />
                  </GridItem>
                  <GridItem w="100%" h="fit-content">
                    <PasswordField
                      label="Confirm Password"
                      value={values.confirmPassword}
                      error={errors.confirmPassword}
                      onChange={(e) => handleChange('confirmPassword', e.target.value)}
                      onBlur={() => handleBlur('confirmPassword')}
                      placeholder="Enter confirm password"
                      isRequired
                    />
                  </GridItem>
                  <GridItem w="100%" h="fit-content">
                    <Button
                      bg={
                        !errors.password &&
                        !errors.confirmPassword &&
                        values.password &&
                        values.confirmPassword &&
                        values.password === values.confirmPassword
                          ? '#2970FF'
                          : '#F04438'
                      }
                      color="white"
                      variant="solid"
                      width="fit-content"
                      _hover={{
                        bg:
                          !errors.password &&
                          !errors.confirmPassword &&
                          values.password &&
                          values.confirmPassword &&
                          values.password === values.confirmPassword
                            ? '#7044c4'
                            : '#F04438',
                      }}
                      onClick={handlePasswordChange}
                      isDisabled={
                        errors.password ||
                        errors.confirmPassword ||
                        !values.password ||
                        !values.confirmPassword ||
                        values.password !== values.confirmPassword
                      }
                    >
                      Change Password
                    </Button>
                  </GridItem>
                </Grid>
              </Box> */}
              <ChangePassword userId={userId} />
            </>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        py={4}
        px={5}
        w="100%"
        bg="white"
        borderTop="1px solid #D1E0FF"
      >
        <Button
          bg={isFormChanged() ? '#2970FF' : '#F04438'}
          color="white"
          variant="solid"
          width={{ base: 'fit-content', md: '150px' }}
          _hover={{ bg: isFormChanged() ? '#7044c4' : '#F04438' }}
          onClick={handleSubmit}
          isDisabled={!isFormChanged()}
          maxW="100%"
        >
          Save Changes
        </Button>
      </Box>
    </Box>
    </ChakraProvider>
  );
};

export default Edituser;
