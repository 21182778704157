import React, { useState, useEffect } from 'react';
import { Box, Button, Text, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, 
  Textarea, Spinner } from '@chakra-ui/react';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { get, patch } from '../../services/api';

const ViewFeedback = ({ isOpen, onClose, feedbackId, refreshFeedbackData  }) => {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && feedbackId) {
      const fetchFeedbackDetails = async () => {
        try {
          const response = await get(`feedbacks/${feedbackId}`);
          const feedbackData = response.data.data;
          setSubject(feedbackData.subject);
          setDescription(feedbackData.description);
          setFiles(feedbackData.uploadDocument);
        } catch (error) {
          console.error('Error fetching feedback details:', error);
        }
      };
      fetchFeedbackDetails();
    }
  }, [isOpen, feedbackId]);

  const updateFeedback = async () => {
    const data = {
      status: 'read',
    };
    try {
      await patch(`feedbacks/${feedbackId}`, data);
      refreshFeedbackData ()
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handlePreview = (file) => {
    setLoading(true);
    setPreviewFile(file);
  };

  const handlePreviewClose = () => {
    setPreviewFile(null);
    setLoading(false);
  };

  const closeModal = () => {
    if (previewFile) {
      handlePreviewClose();
    } else {
      onClose();
    }
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent my={4} p={4} height="fit-content" overflow="hidden" maxW={{ base: '90%', sm: '450px' }}>
        {previewFile && (
          <>
            <ModalHeader px={2} pt={0} pb={2} fontSize='16px'>File Preview</ModalHeader>
            <ModalCloseButton px={2} py={0} color='#2970FF' onClick={handlePreviewClose} />
          </>
        )}
        <ModalBody height='fit-content' overflowY="auto" px={previewFile ? 2 : 5}>
          {previewFile ? (
            <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
              {loading && <Spinner thickness='2px' size='sm' color='#2970FF' />}
              <img
                src={`${process.env.REACT_APP_BASE_URL}/feedbacks/files/${previewFile.fileId}`}
                alt="File Preview"
                style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%', objectFit: 'contain' }}
                onLoad={handleImageLoad}
              />
            </Box>
          ) : (
            <>
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb={2}>Subject</Text>
                <Textarea
                  px={3}
                  border='1px solid #D1E0FF'
                  focusBorderColor='#2970FF'
                  bg="#F5F8FF"
                  borderRadius='10px'
                  placeholder='Subject'
                  size='md' resize={'none'} fontSize="14px"
                  value={subject}
                  minHeight='fit-content'
                  lineHeight='21px'
                  isReadOnly
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                  css={{
                    '::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none'
                  }}
                />
              </Box>
              <br />
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb={2}>Description</Text>
                <Textarea p={3} value={description} isReadOnly border='1px solid #D1E0FF' textAlign="justify" bg='#F5F8FF' borderRadius='10px' focusBorderColor='#D1E0FF' size='sm' resize={'none'} height='150px' 
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                sx={{
                    '::placeholder': {
                      color: '#9DA4AE',
                    },
                  }}
                css={{
                    '::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none'
                }}/>
              </Box>
              <br />
              <Box>
                <Text color='black' fontWeight='500' fontSize='16px' textAlign='justify' lineHeight='22px' mb='18px'>Attachments</Text>
                {files.map((file, index) => (
                  <Box key={file.fileId} display='flex' mb={3} flexDirection='row' justifyContent='space-between' alignItems='center' width={'100%'}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width={'70%'}>
                      <PhotoOutlinedIcon sx={{ color: '#00359E' }} />
                      <Box ml={2} sx={{ flexGrow: 1, maxWidth: 'calc(100% - 70px)', overflow: 'hidden' }}>
                        <Text color="black" fontWeight="500" fontSize="14px" lineHeight="22px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {file.filename}
                        </Text>
                      </Box>
                      <Box display='flex' alignItems='center' ml={3}>
                        <Text as='span' color='#2970FF' fontWeight='400' fontSize='13px' textAlign='right' lineHeight='22px' mr={1}>•</Text>
                        <Link onClick={() => handlePreview(file)} color='#2970FF' style={{ textDecoration: 'underline' }}  _hover={{ textDecorationColor: '#2970FF' }}>
                          <Text color='#2970FF' fontWeight='400' fontSize='13px' textAlign='right' lineHeight='22px'>Preview</Text>
                        </Link>
                      </Box>
                    </Box>
                    <Text color='#00359E' fontWeight='400' fontSize='12px' textAlign='justify' lineHeight='22px'>{(file.size / (1024 * 1024)).toFixed(2)} MB</Text>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </ModalBody>
        {!previewFile && (
          <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
            <Button bg="#2970FF" color='white' variant='solid' width='47.5%' _hover={{ bg: '#7044c4' }} onClick={() => { onClose(); updateFeedback(); }}>
              Done
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ViewFeedback