import '../../App.css';
import React from "react";
import {ChakraProvider, Text, Box, Heading, Input, Button, InputLeftElement, InputGroup, InputRightAddon,useMediaQuery} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import FeedbackTableComponent from '../../components/table/feedbacktable';


function Allfeedback() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = React.useState("");

  const [isSmallScreen] = useMediaQuery('(min-width: 435px)');

  const placeholderText = isSmallScreen
    ? 'Find by User Name, Email Address, Company Name'
    : 'Find by User Name...';

  const placeholderFontSize = isSmallScreen ? 'md' : 'sm';

  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
  };
    
  React.useEffect(() => {
    if (searchTerm === "") {
      handleSearchSubmit();
    }
  }, [searchTerm]);
  return (
    <>
      <ChakraProvider>
        <Box px={{ base: 4, md: 10 }} py={10} w='100%' h='fit-content' overflow-y='scroll'>
            <Box>
                <Heading size='md' fontSize='25px' mb={1} >Feedback</Heading>
            </Box>
            <br/>
            <Box w='100%' display='flex' justifyContent='space-between'>
            <InputGroup w='100%'>
                <Input 
                  type='text'
                  border={`1px solid ${'#D1E0FF'}`} 
                  focusBorderColor='#c5d7fc' 
                  size='md' 
                  placeholder={placeholderText}
                  value={searchTerm} 
                  onChange={handleSearchChange}
                  sx={{
                    '::placeholder': {
                      fontSize: placeholderFontSize,
                    },
                  }}
                />
                <InputRightAddon leftIcon={<SearchIcon />} as={Button} backgroundColor={'#00359E'} border={`1px solid ${'#D1E0FF'}`} color={'white'}  _hover={{ bg: "#00359E" }} onClick={handleSearchSubmit}>
                    Search
                </InputRightAddon>
            </InputGroup>
            </Box>
            <br/>
            <FeedbackTableComponent searchTerm={submittedSearchTerm} />
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Allfeedback;
