import '../../App.css';
import React from "react";
import { Text, Box, Button, Modal,Input, ModalOverlay, ModalContent, ModalFooter, ModalBody, useToast } from '@chakra-ui/react';
import { patch } from '../../services/api';

const ModalEditTechIndicator = ({ isOpen, onClose, reportId, techIndicator, techIndicatorScore, refreshIndustry }) => {
    const toast = useToast();
    const [newTechScore, setNewTechScore] = React.useState(techIndicatorScore);

    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        if (isOpen) {
            setErrors({});
            setNewTechScore(techIndicatorScore);
        }
    }, [isOpen, reportId]);


    const validate = (field, value) => {
        let error = '';

        switch (field) {
            case 'techScore':
                if (value === undefined || value === null || value === '') {
                    error = 'TechIndicator Score is required';
                } else if (isNaN(value)) {
                    error = 'TechIndicator Score must be a number';
                } else if (Number(value) < 0) {
                    error = 'TechIndicator Score cannot be negative';
                }
                break;
            default:
                break;
        }

        return error;
    };

    const handleTechScoreChange = (value) => {
        const error = validate('techScore', value);
        setNewTechScore(value);
        setErrors(prevErrors => ({
            ...prevErrors,
            techScore: error
        }));
    };

    const handleSubmit = async () => {
        const validationErrors = {};

        const techScoreError = validate('techScore', newTechScore);
        if (techScoreError) {
            validationErrors.techScore = techScoreError;
        }

        if (Object.values(validationErrors).some(error => error)) {
            setErrors(validationErrors);
        } else {
            const data = {
                techIndicator: techIndicator,
                techIndicatorScore: newTechScore
            };
            try {
                const response = await patch(`report/techindicator/${reportId}`, data);

                toast({
                    title: response.data.message,
                    description: 'TechIndicator Score has been updated successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
                setNewTechScore()
                refreshIndustry()
                onClose();
            } catch (error) {
                console.error('Error submitting form:', error);
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || 'An error occurred',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
        }
    };

    const isFormValid = () => {
        const hasTechScoreChanged = techIndicatorScore !== newTechScore;

        return  hasTechScoreChanged ;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4} maxW={{ base: '90%', sm: '450px' }}>
                <ModalBody>
                    <Box mb="5">
                        <Text fontWeight="medium" fontSize='14px' mb="2">
                            {techIndicator}
                        </Text>
                        <Input
                            variant='outline'
                            border='1px solid #F3F4F6'
                            focusBorderColor={errors.techScore ? 'red.500' : '#2970FF'}
                            bg="#F5F8FF"
                            placeholder={techIndicator}
                            fontSize="14px"
                            size='md'
                            value={newTechScore}
                            onChange={(e) => handleTechScoreChange(e.target.value)}
                            borderColor={errors.techScore ? 'red.500' : '#D1E0FF'}
                            sx={{
                                '::placeholder': {
                                    color: '#9DA4AE',
                                },
                                whiteSpace: 'nowrap',
                                overflow: 'auto',
                            }}
                        />
                        {errors.techScore && <Text color="red.500" fontSize="sm">{errors.techScore}</Text>}
                    </Box>
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button bg={isFormValid() ? "#2970FF" : "#F04438"} color='white' variant='solid' width='47.5%' _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }} onClick={handleSubmit} isDisabled={!isFormValid()}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalEditTechIndicator;
