import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Button,
  Spinner,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { get, patch } from '../../services/api';
import CompanyInfoForm from '../../components/createAssessment/companyForm';
import FileUpload from '../../components/createAssessment/fileUploader';
import LinkAdder from '../../components/createAssessment/linkAdder';
import SuggestedCategory from '../../components/createAssessment/SuggestedCategory';
import useIndustryTypes from '../../hooks/useIndustryType';

function Upload() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const assessmentId = location.state?.assessmentId || '';

  const [companyName, setCompanyName] = useState('');
  const [numberOfEmployees, setNumberOfEmployees] = useState('');
  const [industryType, setIndustryType] = useState('');
  const industryOptions = useIndustryTypes();

  const [files, setFiles] = useState([]);
  const [filesErrors, setFilesErrors] = useState('');

  const [links, setLinks] = useState([]);
  const [linksErrors, setLinksErrors] = useState('');

  const [selectedOptionSuggest, setSelectedOptionSuggest] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingSections, setIsFetchingSections] = useState(false);

  const [errors, setErrors] = useState({});
  const [sections, setSections] = useState(null); // State for fetched sections

  // Validation function
  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'companyName':
        const companyNameRegex = /^[a-zA-Z0-9 .,&'@#$%^*!()_+=\[\]{};:"|<>?/\\-]+( +[a-zA-Z0-9 .,&'@#$%^*!()_+=\[\]{};:"|<>?/\\-]+){0,49}$/;
        if (!value) {
          error = 'Company name is required';
        } else if (!companyNameRegex.test(value)) {
          error = 'Company name is invalid';
        }
        break;
      case 'numberOfEmployees':
        if (value && value.trim() !== '') {
          if (isNaN(value)) {
            error = 'Number of employees must be a number';
          } else if (value <= 0) {
            error = 'Number of employees must be greater than 0';
          }
        }
        break;
      case 'industryType':
        if (!value) {
          error = 'Industry type is required';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const generateReport = async (e) => {
    e.preventDefault();

    const fieldsToValidate = ['companyName', 'industryType'];
    let hasErrors = false;

    fieldsToValidate.forEach((field) => {
      const value = field === 'companyName' ? companyName : industryType;
      const error = validate(field, value);
      if (error) {
        hasErrors = true;
      }
    });

    if (files.length === 0) {
      setFilesErrors('Files are required');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setIsSubmitting(true);

    const data = new FormData();
    files.forEach((file) => {
      if (file.link) {
        data.append('uploadDocument[]', JSON.stringify(file));
      } else {
        data.append('uploadDocument', file);
      }
    });
    links.forEach((link) => {
      data.append('links[]', link);
    });

    data.append('companyName', companyName);
    data.append('companySize', parseInt(numberOfEmployees) || '');
    data.append('industryType', industryType);

    try {
      const response = await patch(`assessment/${assessmentId}/assessment`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      navigate('/pastAssessment', {
        state: {
          message: response.data.message,
          description: 'You will receive an email once the report is generated.',
        },
      });

      // Reset form
      setCompanyName('');
      setNumberOfEmployees('');
      setIndustryType('');
      setFiles([]);
      setLinks([]);
      setSections(null);
      setSelectedOptionSuggest('');
      setErrors({});
      setFilesErrors('');
      setLinksErrors('');
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: 'Error',
        description: error.response ? error.response.data.message : 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    const isValidCompanyName = companyName && !errors.companyName;
    const isValidIndustryType = industryType && !errors.industryType;
    const hasValidFiles = files.length > 0;
    return isValidCompanyName && isValidIndustryType && hasValidFiles;
  };

  useEffect(() => {
    const fetchSections = async () => {
      if (!industryType) {
        setSections(null);
        setSelectedOptionSuggest('');
        return;
      }

      setIsFetchingSections(true);
      try {
        const response = await get(`assessment/${industryType}/suggestedCategory`);
        setSections(response.data.data.category || null);
        // const firstCategory = response.data.data.category ? Object.keys(response.data.data.category) : '';
        // setSelectedOptionSuggest(firstCategory);
      } catch (error) {
        console.error('Error fetching sections:', error);
        setSections(null);
        setSelectedOptionSuggest('');
        toast({
          title: 'Error',
          description: 'Failed to fetch sections based on industry type.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setIsFetchingSections(false);
      }
    };

    fetchSections();
  }, [industryType, toast]);

  return (
    <ChakraProvider>
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          flex="1"
          overflowY="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          w="100%"
          h="fit-content"
        >
          <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
            <Breadcrumb separator=">" color="#818589" fontWeight="400" fontSize="sm" mb={2}>
              <BreadcrumbItem>
                <BreadcrumbLink href="/assessment">Create Assessment</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink as="button" onClick={() => navigate('/upload')}>
                  Upload Files
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <br />
            <CompanyInfoForm
              companyName={companyName}
              setCompanyName={setCompanyName}
              numberOfEmployees={numberOfEmployees}
              setNumberOfEmployees={setNumberOfEmployees}
              industryType={industryType}
              setIndustryType={setIndustryType}
              industryOptions={industryOptions}
              errors={errors}
              validate={validate}
              handleBlur={handleBlur}
            />
            <br />
            <FileUpload
              files={files}
              setFiles={setFiles}
              errors={filesErrors}
              setErrors={setFilesErrors}
            />
            <br />
            <LinkAdder links={links} setLinks={setLinks} />
            <br />
            <SuggestedCategory
              selectedOptionSuggest={selectedOptionSuggest}
              setSelectedOptionSuggest={setSelectedOptionSuggest}
              sections={sections}
              isFetchingSections={isFetchingSections}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          py={4}
          px={5}
          w="100%"
          bg="white"
          borderTop="1px solid #D1E0FF"
        >
          <Button
            bg={isFormValid() ? '#2970FF' : '#F04438'}
            color="white"
            variant="solid"
            width={{ base: 'fit-content', md: '15%', lg: '25%', xl: '20%' }}
            _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
            onClick={generateReport}
            isDisabled={!isFormValid() || isSubmitting}
            maxW="100%"
          >
            {isSubmitting ? <Spinner size="sm" mr="2" /> : null} Generate Report
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Upload;
